import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../models/types';

@Injectable()
export class StorePaymentService {
    url: string;
    constructor(
        private http: HttpClient,
        @Inject('BASE_URL') baseUrl: string,
    ) {
        this.url = baseUrl;
    }

    getAccess_PaymentInfo(maCH: string) {
        return this.http.post(`${this.url}api/Payment/getAccess_PaymentInfo`, {MaCH: maCH})
        .toPromise()
        .then((result: ServerResponse) => {
            if (result[0].code === 1) {
                return result[0].data;
            }
        });
    }
}
