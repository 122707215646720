import { Injectable, Inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { ServerResponse } from '../models/types';
import { User_AllInfo } from '../models/User.model';

@Injectable()
export class UserService {
  url: string;
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
  ) {
    this.url = baseUrl;
  }


  getAccess_UserInfo(maCH: string) {
    return this.http.post(`${this.url}api/User/getAccess_UserInfo`, { MaCH: maCH })
      .toPromise()
      .then((result: ServerResponse) => {
        if (result[0].code === 1) {
          return result[0].data;
        }
      });
  }


  async setAccess_UserAllInfo(value: User_AllInfo) {
    return await this.http.post(`${this.url}api/User/createAccess_UserAllInfo`, {
      // UserID: value.UserID,
      Name: value.Name,
      Username: value.Username,
      Password: value.Password,
      UserType: value.UserType,
      Status: value.Status,
      StoreID: value.StoreID,
    }).toPromise()
    .then((result: ServerResponse) => {
      console.log(result);
      if (result.code === 1) {
        return result;
      } else {
        throw  new Error(result.message);
      }
    });
  }


  putAccess_StallAllInfo(value: User_AllInfo) {
    return this.http.post(`${this.url}api/User/updateAccess_UserAllInfo`, {
      UserID: value.UserID,
      Name: value.Name,
      Username: value.Username,
      Password: value.Password,
      UserType: value.UserType,
      Status: value.Status,
      StoreID: value.StoreID,
    }).toPromise()
      .then((result: ServerResponse) => {
        console.log(result);
        if (result.code === 1) {
          return result;
        } else {
          throw new Error(result.message);
        }
      });
  }


}
